<template>
  <div class="data-scrollbox d-flex">
    <div
      class="timeline-viewport cy-scrollbars--always-show"
      data-cy="timeline-viewport">
      <CyEventsTimeline
        v-bind="{ events }"
        :options.sync="options"
        iterator-class="py-8"
        data-cy="events-timeline"
        @tag-click="toggleTagFilter">
        <template #pagination>
          <CyDataTablePagination
            v-if="events.length"
            class="v-data-footer py-1"
            :items-per-page-options="[25, 50, 100]"
            :items-length="events.length"
            :options.sync="options"/>
        </template>
      </CyEventsTimeline>
    </div>
    <div class="minimap-viewport">
      <CyEventsFrequencyChart
        class="minimap py-8 px-8 width-100 height-100"
        data-cy="events-frequency-chart"
        :events="events"
        :begin="parseInt($route.query.begin)"
        :end="parseInt($route.query.end)"
        :pagination="_.pick(options, ['itemsPerPage', 'page'])"
        @goToPage="goToPage"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import CyEventsFrequencyChart from '@/components/events/frequency-chart.vue'
import CyEventsTimeline from '@/components/events/timeline.vue'

export default {
  name: 'CyEventsListTable',
  components: {
    CyEventsFrequencyChart,
    CyEventsTimeline,
    CyDataTablePagination,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
    options: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
      'SET_DATA_TABLE_PROPS',
    ]),
    toggleTagFilter (tag) {
      const filters = { ...this.activeFilters }
      const tagFromFilters = _.find(filters, (filterValue, filterKey) => {
        const filterKeyword = filterKey.split('[')[0]
        return filterValue.includes(tag.value) && filterKeyword === tag.key
      })
      const filtersValues = filters[`${tag.key}[in]`]?.split(',') || []
      if (_.$isEmpty(tagFromFilters)) {
        filtersValues.push(tag.value)
      } else {
        const idx = filtersValues.indexOf(tag.value)
        filtersValues.splice(idx, 1)
      }
      filters[`${tag.key}[in]`] = filtersValues.join()
      this.SET_DATA_TABLE_FILTERS({ name: this.$route.name, filters })
    },
    goToPage (event) {
      this.options = {
        ...this.options,
        page: event,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/components/VInput/variables";
@import "~vuetify/src/components/VDataIterator/variables";

$footer-height: $input-max-height + calc(#{$data-footer-select-select-margin-y} * 2);

.data-scrollbox {
  contain: layout;
  position: absolute;
  inset: 0 0 #{$footer-height};
}

.timeline-viewport {
  flex: 1 1 calc(100% - 256px);
}

.minimap-viewport {
  flex: 1 0 256px;
  border-left: 1px solid cy-get-color("grey", "light-2");
}

.v-timeline {
  position: relative;
  max-width: 960px;
  min-height: 100%;
}

.minimap {
  max-height: 100%;
}

::v-deep .v-data-footer {
  position: fixed;
  top: 100%;
  right: 0;
  left: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background-color: map.get($grey, "light-2");
  }
}
</style>
